import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { get } from "lodash";

import request from "../util/request";

import { RootState } from "./index";

export interface PayloadLogin {
  email: string;
  password: string;
}

export interface IInfoUserLogin {
  status: number;
  token: string;
  user: {
    isLog: boolean;
    isCreate: boolean;
    id: number;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    username: string;
  };
  expired: string;
}
const initUser: IInfoUserLogin = {
  status: 0,
  token: "",
  user: {
    isLog: false,
    isCreate: false,
    id: 0,
    displayName: "",
    createdAt: "",
    updatedAt: "",
    username: "",
  },
  expired: "",
};

const initialState = {
  isLogin: true,
  loadingLogin: false,
  userLogin: initUser,
  connectionId: "tidesquare",
};

export const actionLogin = createAsyncThunk(
  "auth/actionLogin",
  async (data: PayloadLogin, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/auth/login",
        method: "POST",
        data,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionLoginKeyCloak = createAsyncThunk(
  "auth/actionLoginKeyCloak",
  async (code: string, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/oauth2/keycloak/login",
        method: "POST",
        data: {
          code,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    actionLogout(state) {
      state.isLogin = false;
      state.userLogin = initUser;
    },
  },
  extraReducers: (builder) => {
    builder
      // LOGIN USER
      .addCase(actionLogin.pending, (state) => {
        state.loadingLogin = true;
      })
      .addCase(actionLogin.fulfilled, (state, action) => {
        state.isLogin = true;
        state.userLogin = get(action, "payload.data");
        state.loadingLogin = false;
      })
      .addCase(actionLogin.rejected, (state) => {
        state.loadingLogin = false;
      })

      .addCase(actionLoginKeyCloak.pending, (state) => {
        state.loadingLogin = true;
      })
      .addCase(actionLoginKeyCloak.fulfilled, (state, action) => {
        const data = get(action, "payload.data");
        state.isLogin = true;
        state.userLogin.token = data.access_token;
        state.loadingLogin = false;
      })
      .addCase(actionLoginKeyCloak.rejected, (state) => {
        state.loadingLogin = false;
      });
  },
});

export const { actionLogout } = slice.actions;

export const selectUser = (state: RootState) => state.auth.userLogin;

export const selectLoadingLogin = (state: RootState) => state.auth.loadingLogin;

export const selectIsLogin = (state: RootState) => state.auth.isLogin;

export const selectToken = (state: RootState) => state.auth.userLogin.token;

export const selectConnectionId = (state: RootState) => state.auth.connectionId;

export default slice.reducer;
